.title {
  max-width: 6rem;
  margin-left: 0.5rem;
  margin-top: 1rem;
}
.text {
  font-size: larger;
  margin-left: 1rem;
  color: white;
}
.btnX {
  position: absolute;
  top: 0;
  right: 0;
  font-size: x-large;
  color: white;
}
